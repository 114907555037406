import CookieConsent from "react-cookie-consent";
import Link from "next/link";

export default function CookieCom() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "#ffffff",
          borderRadius: " 10px 10px 0px 0px",
          justifyContent: "center",
          filter: "drop-shadow(2px 4px 6px black)",
        }}
        buttonStyle={{
          background: "#50136c",
          color: "#ffffff",
          fontSize: "16px",
          borderRadius: " 5px",
        }}
        expires={150}
        enableDeclineButton
        declineButtonText="Decline"
        declineButtonStyle={{
          background: "#f72b51",
          color: "#ffffff",
          fontSize: "16px",
          borderRadius: " 5px",
        }}
        contentClasses="text_con"
      >
        <span style={{ fontSize: "16px", color: "#000" }}>
          We use cookies to provide and improve our services. By using our site,
          you consent to cookies.
        </span>
        <Link href={"/privacy-policy"} prefetch={false}>
          <a
            style={{
              fontSize: "16px",
              color: "#50136c",
              fontWeight: "800",
              textDecoration: "none",
            }}
          >
            {" "}
            Privacy Policy
          </a>
        </Link>
      </CookieConsent>
    </div>
  );
}
